var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { of } from "rxjs";
import { take, catchError, map } from "rxjs/operators";
import { LinksService } from "src/app/services/links.service";
import * as i0 from "@angular/core";
import * as i1 from "../../services/links.service";
var DEFAULT_LINKS = {
    Agir: null,
    "Suivez-nous": null,
    "A notre propos": null,
    Donner: null,
    "S'abonner": null,
};
var UpdateLinkMenuResolver = /** @class */ (function () {
    function UpdateLinkMenuResolver(linksService) {
        this.linksService = linksService;
    }
    UpdateLinkMenuResolver.prototype.resolve = function () {
        return this.linksService.getLinks().pipe(map(function (response) { return (__assign({}, DEFAULT_LINKS, response)); }), take(1), catchError(function () { return of(DEFAULT_LINKS); }));
    };
    UpdateLinkMenuResolver.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateLinkMenuResolver_Factory() { return new UpdateLinkMenuResolver(i0.ɵɵinject(i1.LinksService)); }, token: UpdateLinkMenuResolver, providedIn: "root" });
    return UpdateLinkMenuResolver;
}());
export { UpdateLinkMenuResolver };
