import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, of } from "rxjs";
import { take, catchError, map } from "rxjs/operators";

import { LinksService, LinkInterface } from "src/app/services/links.service";

const DEFAULT_LINKS: LinkInterface = {
  Agir: null,
  "Suivez-nous": null,
  "A notre propos": null,
  Donner: null,
  "S'abonner": null,
};
@Injectable({
  providedIn: "root",
})
export class UpdateLinkMenuResolver
  implements Resolve<Observable<LinkInterface>>
{
  constructor(private readonly linksService: LinksService) {}

  resolve(): Observable<LinkInterface> {
    return this.linksService.getLinks().pipe(
      map((response: { [name: string]: string }) => ({
        ...DEFAULT_LINKS,
        ...response,
      })),
      take(1),
      catchError(() => of(DEFAULT_LINKS))
    );
  }
}
